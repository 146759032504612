/** @format */

import React, { useCallback, useEffect, useRef, useState } from "react";

// import Chevron left from heroicons
// import Chevron right from heroicons
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import Image from "./Image";

interface IGalleryCarouselProps {
	images: string[];
	auto: boolean;
	showArrows?: boolean;
}
const Carousel: React.FC<IGalleryCarouselProps> = ({images, auto, showArrows = true}) => {
	const [imgs, setImages] = useState<string[]>([]);

	const imageRef = useRef(null);
	const [slcImg, setSelectedImage] = useState<number>(0);

	const [height] = useState<number>(430);
	const [width, setWidth] = useState<number>(800);

	const clickWrapper = (event: React.MouseEvent<HTMLElement>, callback: () => void) => {
		event.stopPropagation();
		callback();
	};

	const rotateCarousel = useCallback(
		(right: boolean) => {
			if (right) {
				if (slcImg === imgs.length - 1) setSelectedImage(0);
				else setSelectedImage(slcImg + 1);
			} else {
				if (slcImg === 0) setSelectedImage(imgs.length - 1);
				else {
					setSelectedImage(slcImg - 1);
				}
			}
		},
		[imgs.length, slcImg]
	);

	const nextImageHandler = () => {
		rotateCarousel(true);
	};

	const previousImageHandler = () => {
		rotateCarousel(false);
	};

	useEffect(() => {
		if (auto) {
			const interval = setInterval(() => {
				rotateCarousel(true);
			}, 8000);
			return () => clearInterval(interval);
		}
	}, [slcImg, auto, rotateCarousel]);

	useEffect(() => {
		setImages(images);
		setSelectedImage(0);
	}, [images]);

	useEffect(() => {
		const handleResize = () => {
			if (imageRef.current) {
				const image = imageRef.current as HTMLImageElement;
				setWidth(image.clientWidth);
			}
		};
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [height]);

	useEffect(() => {}, [width]);

	return (
		<div className="w-full relative lg:h-80 md:h-64 sm:h-52 h-44 z-20 select-none">
			<div className="absolute h-full w-full grid grid-cols-10 grid-rows-1 z-40 select-none cursor-pointer">
				<div
					className={`${
						!showArrows ? "hidden" : ""
					} flex items-center justify-center cursor-pointer`}
					onClick={(e) => clickWrapper(e, previousImageHandler)}
				>
					<ChevronLeftIcon className="w-6 h-6 text-gray-400" />
				</div>
				<div
					ref={imageRef}
					className={`${
						!showArrows ? "col-span-10" : "col-span-8"
					} flex justify-center items-center relative`}
				>
					{/*{loading ? <div>Loading...</div> : null}*/}

					{images.map((img, index) => (
						<Image
							className={`${
								index === slcImg ? "block" : "hidden"
							} max-h-full object-scale-down cursor-default rounded-xl transition-all duration-100 drop-shadow-sm`}
							key={index}
							src={`${img}`}
							alt={"test"}
						/>
					))}
				</div>
				<div
					className={`${
						!showArrows ? "hidden" : ""
					} flex items-center justify-center cursor-pointer`}
					onClick={(e) => clickWrapper(e, nextImageHandler)}
				>
					<ChevronRightIcon className="w-6 h-6 text-gray-400" />
				</div>
			</div>
		</div>
	);
};

export default Carousel;
