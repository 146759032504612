/** @format */

import { WhatsappIcon } from "react-share";

import { PhoneIcon } from "@heroicons/react/20/solid";

import emailjs from "@emailjs/browser";
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SERVICE_ID = "service_03vuo7f";
const TEMPLATE_ID = "template_fhw8vzn";
const USER_ID = "oN_chg0lIthJoXU5v";

type Inputs = {
  name: string;
  email: string;
  phone: string;
  description: string;
};

const BookContact: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation("");
  const [sending, setSending] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  const handleOnSubmit: SubmitHandler<Inputs> = (data) => {
    setSending(true);
    emailjs
      .send(SERVICE_ID, TEMPLATE_ID, data, {
        publicKey: USER_ID,
      })
      .then(
        () => {
          alert("The contact form has been submitted!");
          navigate("/");
        },
        (error) => {
          console.error(error);
        }
      );
  };

  return (
    <div className="">
      <div className={"flex flex-col"}>
        <p className={"self-center pb-4 px-6 lg:px-8"}>
          {t("contact.inquiryText")}
        </p>

        <div className={"flex gap-8 items-center self-center"}>
          <a
            className={"self-center"}
            href={"https://wa.me/393281188565"}
            target={"_blank"}
            rel={"noreferrer"}
          >
            <WhatsappIcon size={32} round />
          </a>

          <a className={"flex"} href="tel:+393505289704">
            <PhoneIcon className={"w-6"} />
          </a>
        </div>

        <a className={"self-center pt-3"} href="tel:+393505289704">
          +39 350 5289704
        </a>

        <div className="relative flex items-center max-w-6xl px-6 w-full self-center pt-2 lg:px-8">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="flex-shrink mx-4 text-gray-400">
            {t("contact.or")}
          </span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>

        <div className="flex justify-center">
          <form
            onSubmit={handleSubmit(handleOnSubmit)}
            className="flex flex-col gap-6 flex-grow max-w-6xl px-6 py-6 lg:px-8 lg:py-8"
          >
            <div className={"flex flex-col gap-6"}>
              <div className="w-full flex-1 relative">
                <label
                  htmlFor={"name"}
                  className={
                    "absolute bg-secondary-50 -top-2 text-xs left-3 px-1"
                  }
                >
                  {t("contact.name")}
                </label>
                <input
                  type="text"
                  id="name"
                  {...register("name", { required: true })}
                  className={`placeholder:text-gray-300 outline-4 outline-primary-500 appearance-none bg-secondary-50 w-full h-10 indent-3 px-1 text-base text-gray-700 placeholder-gray-600 border border-secondary-200 rounded-lg`}
                  placeholder="John Doe"
                  readOnly={false}
                />
                {errors.name && (
                  <span className={"text-red-600 text-sm"}>
                    {t("forms.requiredField")}
                  </span>
                )}
              </div>
              <div className="w-full flex-1 relative">
                <label
                  htmlFor={"email"}
                  className={
                    "absolute bg-secondary-50 -top-2 text-xs left-3 px-1"
                  }
                >
                  {t("contact.email")}
                </label>
                <input
                  type="email"
                  id="email"
                  {...register("email", { required: true })}
                  className={`placeholder:text-gray-300 outline-4 outline-primary-500 appearance-none bg-secondary-50 w-full h-10 indent-3 px-1 text-base text-gray-700 placeholder-gray-600 border border-secondary-200 rounded-lg`}
                  placeholder="example@examp.le"
                  readOnly={false}
                />
                {errors.email && (
                  <span className={"text-red-600 text-sm"}>
                    {t("forms.requiredField")}
                  </span>
                )}
              </div>
              <div className="w-full flex-1 relative">
                <label
                  htmlFor={"phone"}
                  className={
                    "absolute bg-secondary-50 -top-2 text-xs left-3 px-1"
                  }
                >
                  {t("contact.phone")}
                </label>
                <input
                  type="text"
                  id="phone"
                  {...register("phone", { required: true })}
                  className={`placeholder:text-gray-300 outline-4 outline-primary-500 appearance-none bg-secondary-50 w-full h-10 indent-3 px-1 text-base text-gray-700 placeholder-gray-600 border border-secondary-200 rounded-lg`}
                  placeholder="123-45-678"
                  readOnly={false}
                />
                {errors.phone && (
                  <span className={"text-red-600 text-sm"}>
                    {t("forms.requiredField")}
                  </span>
                )}
              </div>

              <div className={"w-full flex-1 relative"}>
                <label
                  htmlFor={"comment"}
                  className={
                    "absolute bg-secondary-50 -top-2 text-xs left-3 px-1"
                  }
                >
                  {t("contact.comment")}
                </label>

                <textarea
                  rows={4}
                  id="comment"
                  {...register("description", { required: true })}
                  className="indent-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm bg-secondary-50 ring-1 ring-inset ring-secondary-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-secondary-600 sm:text-sm sm:leading-6"
                  defaultValue={""}
                />
                {errors.description && (
                  <span className={"text-red-600 text-sm"}>
                    {t("forms.requiredField")}
                  </span>
                )}
              </div>
            </div>
            <div className={"flex"}>
              <button
                type={"submit"}
                disabled={sending}
                className={`${
                  sending ? "disabled" : ""
                } text-sm font-semibold leading-6 py-2 px-4 transition-colors bg-primary-400 hover:bg-primary-500 rounded-md text-white`}
              >
                {t("forms.send")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BookContact;
