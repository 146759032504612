/** @format */

import { Dialog, Transition } from "@headlessui/react";
import {
	ChevronDownIcon,
	ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useRef, useState } from "react";

import SingleDatePicker from "../../../components/SingleDatePicker";
import { apiBaseUrl } from "../../../utils/variables";
import { IRoom } from "../../rooms/Rooms";

interface IBooking {
	id: string;
	room_id: number;
	startdate: string;
	enddate: string;
	person: string;
	room: string;
	email: string;
	phone: string;
	peopleamount: number;
	confirmed: boolean;
	paid: boolean;
}

interface IDBBooking extends IBooking {
	created: string;
}

export const BookingAdmin: React.FC = () => {
	const [bookings, setBookings] = useState<IDBBooking[]>([]);
	const [rooms, setRooms] = useState<IRoom[]>([]);
	const [open, setOpen] = useState(false);
	const [addOpen, setAddOpen] = useState(false);
	const cancelButtonRef = useRef(null);
	const cancelAddButtonRef = useRef(null);

	const [edit, setEdit] = useState<boolean>(false);

	const [delArticle, setDelArticle] = useState<string>("");
	const [editBooking, setEditBooking] = useState<string>("");

	const [room, setRoom] = useState<number>(0);
	const [from, setFrom] = useState(new Date());
	const [to, setTo] = useState(new Date());
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [persons, setPersons] = useState<number>(1);
	const [confirmed, setConfirmed] = useState<boolean>(false);
	const [paid, setPaid] = useState<boolean>(false);

	const getBookings = async () => {
		const response = await fetch(`${apiBaseUrl}/bookings`);
		const items = await response.json();
		items.sort((a: IDBBooking, b: IDBBooking) => {
			const x = new Date(a.created),
				y = new Date(b.created);
			if (x > y) {
				return 1;
			}
			if (x < y) {
				return -1;
			}
			return 0;
		});
		setBookings(items);
	};

	const getRooms = async () => {
		const response = await fetch(`${apiBaseUrl}/rooms`);
		const items = await response.json();
		setRooms(items);
	};

	useEffect(() => {
		getRooms();

		getBookings();
	}, []);

	useEffect(() => {
	}, [room]);

	const addBooking = async () => {
		await fetch(`${apiBaseUrl}/bookings`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: JSON.stringify({
				from: from,
				to: to,
				name: name,
				email: email,
				phone: phone,
				paid: paid,
				room: room,
				peopleamount: persons,
				confirmed: confirmed,
				// b.from, b.to, b.name, b.email, b.phone, b.paid, b.room, b.peopleamount, b.confirmed
			}),
		});
		getBookings();
		setAddOpen(false);
	};

	const editB = async () => {
		await fetch(`${apiBaseUrl}/bookings/${editBooking}`, {
			method: "PUT",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
				// 'Content-Type': 'application/x-www-form-urlencoded',
			},
			body: JSON.stringify({
				from: from,
				to: to,
				name: name,
				email: email,
				phone: phone,
				paid: paid,
				room: room,
				peopleamount: persons,
				confirmed: confirmed,
				// b.from, b.to, b.name, b.email, b.phone, b.paid, b.room, b.peopleamount, b.confirmed
			}),
		});
		getBookings();
		setAddOpen(false);
	};

	const deleteArticle = async (id: string) => {
		setDelArticle(id);
		setOpen(true);
	};

	const confirmDelete = async () => {
		await fetch(`${apiBaseUrl}/bookings/${delArticle}`, {
			method: "DELETE",
			credentials: "include",
		});
		getBookings();
		setOpen(false);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-8">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-base font-semibold leading-6 text-gray-900">
						Bookings
					</h1>
					<p className="mt-2 text-sm text-gray-700">
						A list of all the bookings.
					</p>
				</div>
				<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
					<button
						type="button"
						className="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						onClick={() => {
							setEdit(false);
							setAddOpen(true);
						}}
					>
						Add booking
					</button>
				</div>
			</div>
			<div className="mt-8 flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
										>
											Room
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											From
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											To
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Person
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Email
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Phone
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Persons
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Confirmed
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Paid
										</th>

										<th
											scope="col"
											className="relative py-3.5 pl-3 pr-4 sm:pr-6"
										>
											<span className="sr-only">
												Edit
											</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{bookings.map((booking, index) => {
										return (
											<tr key={index}>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
													{booking.room}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{new Date(
														booking.startdate
													).toLocaleDateString()}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{new Date(
														booking.enddate
													).toLocaleDateString()}
												</td>

												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{booking.person}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{booking.email}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{booking.phone}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{booking.peopleamount}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{booking.confirmed
														? "Yes"
														: "No"}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{booking.paid
														? "Yes"
														: "No"}
												</td>

												<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex gap-2">
													<button
														className="text-primary-600 hover:text-primary-900"
														onClick={() => {
															setEdit(true);

															setRoom(
																booking.room_id
															);
															setEditBooking(
																booking.id
															);
															setFrom(
																new Date(
																	booking.startdate
																)
															);
															setTo(
																new Date(
																	booking.enddate
																)
															);

															setName(
																booking.person
															);
															setEmail(
																booking.email
															);
															setPhone(
																booking.phone
															);
															setPersons(
																booking.peopleamount
															);
															setConfirmed(
																booking.confirmed
															);
															setPaid(
																booking.paid
															);

															setAddOpen(true);
														}}
													>
														Edit
													</button>
													<button
														className="text-red-600 hover:text-red-900"
														onClick={() =>
															deleteArticle(
																booking.id
															)
														}
													>
														Delete
													</button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<Transition.Root show={open} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={cancelButtonRef}
					onClose={setOpen}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div className="sm:flex sm:items-start">
										<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationTriangleIcon
												className="h-6 w-6 text-red-600"
												aria-hidden="true"
											/>
										</div>
										<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
											<Dialog.Title
												as="h3"
												className="text-base font-semibold leading-6 text-gray-900"
											>
												Delete booking
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
													Are you sure you want to
													delete this booking? All of
													the related data will be
													permanently removed from the
													database servers forever.
													This action cannot be
													undone.
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
											onClick={() => confirmDelete()}
										>
											Delete
										</button>
										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
											onClick={() => setOpen(false)}
											ref={cancelButtonRef}
										>
											Cancel
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
			<Transition.Root show={addOpen} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={cancelAddButtonRef}
					onClose={setAddOpen}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
									<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
										<form className={"flex flex-col gap-4"}>
											<SingleDatePicker
												date={from}
												setDate={setFrom}
												label={"Check-in"}
											/>
											<SingleDatePicker
												date={to}
												setDate={setTo}
												label={"Check-out"}
											/>

											<div
												className={
													"flex-1 relative h-full"
												}
											>
												<div className="relative">
													<select
														id="rooms"
														name="rooms"
														value={room}
														className="appearance-none bg-white border-gray-200 outline-offset-2 cursor-pointer w-full h-10 indent-3 px-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg"
														onChange={(e) => {
															setRoom(
																parseInt(
																	e.target
																		.value
																)
															);
														}}
														defaultValue={room}
													>
														{rooms.map(
															(room) => (
																<option
																	value={
																		room.id
																	}
																	key={
																		room.id
																	}
																>
																	{room.name}
																</option>
															)
														)}
													</select>
													<ChevronDownIcon
														className={`cursor-pointer transition-all absolute top-3 right-3 w-5 h-5 text-gray-500`}
													/>
												</div>

												<label
													htmlFor="rooms"
													className="absolute bg-white -top-2 text-xs left-3 px-1"
												>
													Room
												</label>
											</div>

											<div
												className={
													"flex-1 relative h-full"
												}
											>
												<div className="relative">
													<select
														id="peopleamount"
														name="peopleamount"
														className="appearance-none bg-white border-gray-200 outline-offset-2 cursor-pointer w-full h-10 indent-3 px-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg"
														onChange={(e) =>
															setPersons(
																parseInt(
																	e.target
																		.value
																)
															)
														}
														defaultValue={persons}
													>
														<option>1</option>
														<option>2</option>
														<option>3</option>
													</select>
													<ChevronDownIcon
														className={`cursor-pointer transition-all absolute top-3 right-3 w-5 h-5 text-gray-500`}
													/>
												</div>

												<label
													htmlFor="peopleamount"
													className="absolute bg-white -top-2 text-xs left-3 px-1"
												>
													Persons
												</label>
											</div>

											<div className="w-full flex-1 relative">
												<label
													htmlFor={"name"}
													className={
														"absolute bg-white -top-2 text-xs left-3 px-1"
													}
												>
													Name
												</label>
												<input
													type="text"
													id="name"
													name={"name"}
													value={name}
													onChange={(e) =>
														setName(e.target.value)
													}
													className={`appearance-none bg-white border-gray-200 outline-offset-2 cursor-pointer w-full h-10 indent-3 px-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg`}
													placeholder="John Doe"
													readOnly={false}
												/>
											</div>

											<div className="w-full flex-1 relative">
												<label
													htmlFor={"email"}
													className={
														"absolute bg-white -top-2 text-xs left-3 px-1"
													}
												>
													Email
												</label>
												<input
													type="email"
													id="email"
													name={"email"}
													value={email}
													onChange={(e) =>
														setEmail(e.target.value)
													}
													className={`appearance-none bg-white border-gray-200 outline-offset-2 cursor-pointer w-full h-10 indent-3 px-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg`}
													placeholder="example@example.me"
													readOnly={false}
												/>
											</div>

											<div className="w-full flex-1 relative">
												<label
													htmlFor={"phone"}
													className={
														"absolute bg-white -top-2 text-xs left-3 px-1"
													}
												>
													Phone
												</label>
												<input
													type="text"
													id="phone"
													name={"phone"}
													value={phone}
													onChange={(e) =>
														setPhone(e.target.value)
													}
													className={`appearance-none bg-white border-gray-200 outline-offset-2 cursor-pointer w-full h-10 indent-3 px-1 text-base text-gray-700 placeholder-gray-600 border rounded-lg`}
													placeholder="123-45-67"
													readOnly={false}
												/>
											</div>

											<div className="relative flex gap-x-3">
												<div className="flex h-6 items-center">
													<input
														checked={paid}
														onChange={(e) =>
															setPaid(
																e.target.checked
															)
														}
														id="paid"
														name="paid"
														type="checkbox"
														className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
													/>
												</div>
												<div className="text-sm leading-6">
													<label
														htmlFor="paid"
														className="font-medium text-gray-900"
													>
														Paid
													</label>
													<p className="text-gray-500">
														Has the person already
														paid?
													</p>
												</div>
											</div>

											<div className="relative flex gap-x-3">
												<div className="flex h-6 items-center">
													<input
														checked={confirmed}
														onChange={(e) =>
															setConfirmed(
																e.target.checked
															)
														}
														id="confirmed"
														name="confirmed"
														type="checkbox"
														className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
													/>
												</div>
												<div className="text-sm leading-6">
													<label
														htmlFor="confirmed"
														className="font-medium text-gray-900"
													>
														Confirmed
													</label>
													<p className="text-gray-500">
														Has the person confirmed
														their stay?
													</p>
												</div>
											</div>
										</form>
									</div>
									<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 sm:ml-3 sm:w-auto"
											onClick={() => {
												edit ? editB() : addBooking();
												setAddOpen(false);
											}}
										>
											Save
										</button>
										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
											onClick={() => setAddOpen(false)}
											ref={cancelButtonRef}
										>
											Cancel
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	);
};
