/** @format */

import React from "react";
import Router from "./router";

const App: React.FC = () => {
	return <Router />;
}

export default App;
