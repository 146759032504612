/** @format */

import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../app/hooks";
import { baseUrl } from "../../utils/variables";
import { openCarousel, rotateCarousel } from "./gallery.slice";

interface IGalleryImageProps {
	src: string;
	alt: string;
	index: number;
}

const Image: React.FC<IGalleryImageProps> = ({src, alt, index}) => {
	const dispatch = useAppDispatch();

	const [width, setWidth] = useState<number>(500);
	const [height, setHeight] = useState<number>(600);
	const baseImage = `${baseUrl}/images/${src}`;
	const [image, setImage] = useState<string>(`${baseImage}?width=${width}`);
	const imageRef = useRef(null);

	const [loaded, setLoaded] = useState<boolean>(false);

	useEffect(() => {
		const handleResize = () => {
			if (imageRef.current) {
				const image = imageRef.current as HTMLImageElement;
				const w = image.clientWidth;
				const h = image.clientHeight;
				setWidth(w);
				setHeight(h);
			}
		};
		setImage(`${baseImage}?width=${width}`);
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [baseImage, width]);

	const openImage = (idx: number) => {
		dispatch(rotateCarousel(idx));
		dispatch(openCarousel());
	};

	return (
		<div
			className={`relative before:block ${
				loaded ? "before:hidden" : null
			} before:absolute before:inset-0 before:content-[''] before:bg-[rgba(255,255,255,0.2)] before:animate-pulse max-h-96 h-[${height}px] w-full bg-cover bg-center rounded-xl overflow-hidden`}
			style={{backgroundImage: `url(${baseImage}?width=20)`}}
		>
			<img
				ref={imageRef}
				src={image}
				width={width}
				height={height}
				alt={alt}
				className={`w-full h-full object-cover object-center block transition-opacity duration-200 opacity-0 ${
					loaded ? "opacity-100" : null
				}`}
				onClick={() => openImage(index)}
				onLoad={() => setLoaded(true)}
				loading={"lazy"}
			/>
		</div>
	);
};

export default Image;
