/** @format */

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import RoomItem, { RoomItemSM } from "./RoomItem";

import ImageTextSection from "../../components/ImageTextSection";
import TopTile from "../../components/TopTitle";
import { apiBaseUrl } from "../../utils/variables";

export interface IRoom {
	id: number;
	name: string;
	people: number;
	low: number;
	mid: number;
	high: number;
}

const images1 = "accomodation/1";
const images2 = "accomodation/2";
const images3 = "accomodation/3";
const images4 = "accomodation/1";

interface IRoomsProps {}
const Rooms: React.FC<IRoomsProps> = () => {
	const {t} = useTranslation("");
	const [rooms, setRooms] = useState<IRoom[]>([]);

	const fetchRooms = async () => {
		const response = await fetch(`${apiBaseUrl}/rooms`);
		const rooms = await response.json();

		setRooms(
			rooms.sort((a: IRoom, b: IRoom) => {
				return a.id > b.id ? 1 : -1;
			})
		);
	};

	useEffect(() => {
		fetchRooms();
	}, []);

	return (
		<div>
			<TopTile
				title={t("accommodation.title")}
				imageUrl="accomodation/overview accomodation.jpg"
			/>
			<div className="flex justify-center">
				<div className="flex-grow max-w-6xl px-6 py-6 lg:px-8 lg:py-8">
					<div className="w-full">
						{/*<h1 className="text-xl font-bold text-gray-900">*/}
						{/*	{t("accommodation.title")}*/}
						{/*</h1>*/}

						{/*{images.length > 0 ? (*/}
						{/*	<Carousel images={images} auto={true} />*/}
						{/*) : null}*/}

						<ImageTextSection
							gallery={true}
							imagePath={images1}
							imgRight={false}
							text={t("accommodation.1")}
						/>
						<ImageTextSection
							gallery={true}
							imagePath={images2}
							imgRight={true}
							text={t("accommodation.2")}
						/>
						<ImageTextSection
							gallery={true}
							imagePath={images3}
							imgRight={false}
							text={t("accommodation.3")}
						/>
						<ImageTextSection
							gallery={true}
							imagePath={images4}
							imgRight={true}
							text={t("accommodation.4")}
						/>

						<h1 className="text-lg font-bold text-gray-900 mt-10">
							{t("accommodation.amenities.title")}
						</h1>
						<ul className="list-disc">
							<li className="mt-2 ml-10">
								{t("accommodation.amenities.free_wifi")}
							</li>
							<li className="mt-2 ml-10">
								{t("accommodation.amenities.air_conditioning")}
							</li>
							<li className="mt-2 ml-10">
								{t("accommodation.amenities.satellite_tv")}
							</li>
							<li className="mt-2 ml-10">
								{t("accommodation.amenities.hair_drying")}
							</li>
							<li className="mt-2 ml-10">
								{t("accommodation.amenities.toiletries")}
							</li>
							<li className="mt-2 ml-10">
								{t("accommodation.amenities.free_car_parking")}
							</li>
							<li className="mt-2 ml-10">
								{t("accommodation.amenities.daily_cleaning")}
							</li>
							<li className="mt-2 ml-10">
								{t("accommodation.amenities.non_smoking")}
							</li>
							<li className="mt-2 ml-10">
								{t(
									"accommodation.amenities.principal_credit_cards_accepted"
								)}
							</li>
							<li className="mt-2 ml-10">
								{t("accommodation.amenities.swimming_pool")}
							</li>
							<li className="mt-2 ml-10">
								{t(
									"accommodation.amenities.rental_e_mountain_bikes"
								)}
							</li>
						</ul>
						<h1 className="text-lg font-bold text-gray-900 mt-10">
							{t("accommodation.rooms.title")}
						</h1>
					</div>
					<div className="lg:hidden flex flex-col gap-4">
						{rooms.map((room, index) => (
							<RoomItemSM
								key={index}
								room={room}
								roomInx={index}
							/>
						))}
					</div>
					<div className="px-4 sm:px-6 lg:px-8 hidden lg:block">
						<div className="mt-8 flow-root">
							<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
								<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
									<table className="min-w-full divide-y divide-gray-400">
										<thead>
											<tr className="divide-x divide-gray-400">
												<th
													scope="col"
													className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0"
												>
													{t(
														"accommodation.rooms.table.title"
													)}
												</th>
												<th
													scope="col"
													className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													{t(
														"accommodation.rooms.table.numberOfPeople"
													)}
												</th>
												<th
													scope="col"
													className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
												>
													{t(
														"accommodation.rooms.table.priceLowSeason"
													)}
												</th>
												<th
													scope="col"
													className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0"
												>
													{t(
														"accommodation.rooms.table.priceMidSeason"
													)}
												</th>
												<th
													scope="col"
													className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0"
												>
													{t(
														"accommodation.rooms.table.priceHighSeason"
													)}
												</th>
											</tr>
										</thead>
										<tbody className="divide-y divide-gray-200 bg-white">
											{rooms.map((room, roomInx) => (
												<RoomItem
													room={room}
													roomInx={roomInx}
													key={roomInx}
												/>
											))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div>
						<p className="mt-4">
							{t("accommodation.explanation.low")}
						</p>
						<p className="mt-4">
							{t("accommodation.explanation.mid")}
						</p>
						<p className="mt-4">
							{t("accommodation.explanation.high")}
						</p>
					</div>
					{/*<div id="smoobuCalendarIframe">*/}

					{/*		<iframe className="lg:hidden smallDevices" height="540px" width="280px"*/}
					{/*				src="https://login.smoobu.com/de/cockpit/widget/show-calendar-iframe/1700054/861d82bce4598a72c46f8579f5993bd3a07d14b282b245c391e09478255fbc7d"></iframe>*/}
					{/*		<iframe className="hidden lg:block bigDevices" height="600px" width="1000px"*/}
					{/*				src="https://login.smoobu.com/de/cockpit/widget/show-calendar-iframe/1700054/861d82bce4598a72c46f8579f5993bd3a07d14b282b245c391e09478255fbc7d"></iframe>*/}
					{/*</div>*/}
				</div>
			</div>
		</div>
	);
};

export default Rooms;
