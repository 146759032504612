/** @format */

import React from "react";
import Divider from "../components/Divider";
import { SmallNavbar } from "../components/Navbar";
import { baseUrl } from "../utils/variables";

interface IFooterProps {}
const Footer: React.FC<IFooterProps> = () => {
	return (
		<footer className={"mt-auto"}>
			<div className="flex justify-between">
				<div>
					<div>
						<img
							src={`${baseUrl}/images/Logos/full_margin_transparent_base.png?width=600`}
							alt="Our logo - Cascina Cipressa"
							className="h-32 w-auto"
						/>
					</div>
					<div>
						<SmallNavbar />
					</div>
				</div>
			</div>
			<Divider />
			{/* add copyright string */}

			<div className="text-sm mt-6 lg:mt-8">
				&copy; 2023 Cascina Cipressa, All rights reserved
			</div>
		</footer>
	);
};

export default Footer;
