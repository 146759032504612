/** @format */

import React from "react";
import { Outlet } from "react-router-dom";
import Banner from "../../components/Banner";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const Home: React.FC = () => {
	return (
		<div className="bg-secondary-50 text-black flex flex-col justify-start min-h-screen">
			<Banner />
			<div className="flex justify-center z-10">
				<div className="max-w-6xl w-full px-6 py-2 lg:px-8 lg:py-4">
					<Navbar />
				</div>
			</div>

			<div className="flex-grow flex justify-center z-0">
				<main className="w-full">
					<Outlet />
				</main>
			</div>

			<div className="flex justify-center z-0 bg-secondary-100">
				<footer className="max-w-6xl w-full px-6 pb-6 pt-2 lg:px-8 lg:pb-8 lg:pt-2">
					<Footer />
				</footer>
			</div>
		</div>
	);
}

export default Home;
