/** @format */
import React from "react";
import { useTranslation } from "react-i18next";
import ImageTextSection from "../../components/ImageTextSection";
import TopTile from "../../components/TopTitle";

const images1 = "WineOlives/1";
const images2 = "WineOlives/2";
const images3 = "WineOlives/3";
const images4 = "WineOlives/4";

const OurProducts: React.FC = () => {
	const {t} = useTranslation("");

	return (
		<div className="">
			<TopTile
				title={t("ourProducts.title")}
				imageUrl="WineOlives/overview our products.jpg"
			/>

			<div className="flex justify-center">
				<div className="flex-grow max-w-6xl px-6 py-6 lg:px-8 lg:py-8">
					{/* <Carousel images={images} auto={true} /> */}
					<ImageTextSection
						gallery={true}
						imagePath={images1}
						imgRight={false}
						text={t("ourProducts.1")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images2}
						imgRight={true}
						text={t("ourProducts.2")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images3}
						imgRight={false}
						text={t("ourProducts.3")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images4}
						imgRight={true}
						text={t("ourProducts.4")}
					/>
				</div>
			</div>
		</div>
	);
};

export default OurProducts;
