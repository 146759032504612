/** @format */

import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { apiBaseUrl } from "../../utils/variables";

// import Chevron left from heroicons
// import Chevron right from heroicons
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	XMarkIcon,
} from "@heroicons/react/24/outline";

import {
	closeCarousel,
	rotateCarousel,
	setImages,
} from "../../features/gallery/gallery.slice";
import Image from "../Image";

interface IGalleryCarouselProps {}
const GalleryCarousel: React.FC<IGalleryCarouselProps> = () => {
	const gallery = useAppSelector((state) => state.gallery);
	const dispatch = useAppDispatch();

	const [loading, setLoading] = useState<boolean>(true);

	const closeCarouselHandler = () => {
		dispatch(closeCarousel());
	};

	const imageClickHandler = (event: React.MouseEvent<HTMLImageElement>) => {
		event.stopPropagation();
	};

	const nextImageHandler = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();

		if (gallery.selectedIndex === gallery.images.length - 1) return;
		dispatch(rotateCarousel(gallery.selectedIndex + 1));
	};

	const previousImageHandler = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();

		if (gallery.selectedIndex === 0) return;
		dispatch(rotateCarousel(gallery.selectedIndex - 1));
	};

	useEffect(() => {
		const getImages = async () => {
			const res = await fetch(`${apiBaseUrl}/image-list`);
			const data = await res.json();

			const tempImages: string[] = [];

			// console.table(data);
			for (const image of data) {
				tempImages.push(`${image}`);
			}

			dispatch(setImages(tempImages));
			setLoading(false);
		};

		getImages();
	}, [dispatch]);

	if (!gallery.showCarousel) return null;
	return (
		<div className="fixed top-0 w-screen h-screen z-20">
			<div className="block absolute w-full h-full bg-gray-100 z-30"></div>
			<div
				className="absolute h-full w-full grid grid-cols-10 grid-rows-1 z-40 select-none cursor-pointer"
				onClick={closeCarouselHandler}
			>
				<div
					className="flex items-center justify-center cursor-pointer"
					onClick={previousImageHandler}
				>
					<ChevronLeftIcon className="h-10 w-10 text-gray-400" />
				</div>
				<div className="col-span-8 flex justify-center items-center py-10">
					{loading ? (
						<div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900">
							Loading
						</div>
					) : null}
					{gallery.images.map(
						(image, index) => (
							<Image
								key={index}
								onClick={imageClickHandler}
								src={image}
								alt={image}
								className={`${
									index === gallery.selectedIndex
										? "block"
										: "hidden"
								} ${
									loading ? "hidden" : "block"
								} max-h-full object-scale-down cursor-default rounded-xl`}
								fixedWidth={800}
							/>
						)
						// <img
						// 	ref={addToRefs}
						// 	key={image}
						// 	onClick={imageClickHandler}
						// 	src={`${image}?width=${width}`}
						// 	alt={image}
						// 	// width={width.toString()}
						// 	className={`${index === gallery.selectedIndex ? "block" : "hidden"} ${loading ? "hidden" : "block"} max-h-full object-scale-down cursor-default rounded-xl`}
						// />
					)}
				</div>
				<div
					className="flex items-center justify-center cursor-pointer"
					onClick={nextImageHandler}
				>
					<ChevronRightIcon className="h-10 w-10 text-gray-400" />
				</div>
				<div
					className="absolute right-10 top-10 h-50"
					onClick={closeCarouselHandler}
				>
					<XMarkIcon className="h-6 w-6 text-gray-400" />
				</div>
			</div>
		</div>
	);
};

export default GalleryCarousel;
