/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import ImageTextSection from "../../components/ImageTextSection";
import TopTile from "../../components/TopTitle";

const images1 = "DomainViews/1";

const images2 = "DomainViews/2";

const images3 = "DomainViews/3";

const images4 = "DomainViews/4";

const Welcome: React.FC = () => {
	const {t} = useTranslation("");

	return (
		<div className="">
			<TopTile
				title={t("welcome.title")}
				imageUrl="DomainViews/overview.jpg"
			/>

			<div className="flex justify-center">
				<div className="flex-grow max-w-6xl px-6 py-6 lg:px-8 lg:py-8">
					{/* <Carousel images={images} auto={true} /> */}
					<ImageTextSection
						gallery={true}
						imagePath={images1}
						imgRight={false}
						text={t("welcome.1")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images2}
						imgRight={true}
						text={t("welcome.2")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images3}
						imgRight={false}
						text={t("welcome.3")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images4}
						imgRight={true}
						text={t("welcome.4")}
					/>
				</div>
			</div>
		</div>
	);
};

export default Welcome;
