/** @format */

import React, {
	DetailedHTMLProps,
	ImgHTMLAttributes,
	useEffect,
	useRef,
	useState,
} from "react";
import { baseUrl } from "../utils/variables";

type IImage = Omit<
	DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
	"ref"
> & {
	fixedWidth?: number;
};

const Image: React.FC<IImage> = ({fixedWidth, ...props}) => {
	const [width, setWidth] = useState<number>(fixedWidth || 800);
	const imageRef = useRef<HTMLImageElement>();
	const baseImage = `${baseUrl}/images/${props.src}`;
	const [image, setImage] = useState<string>(`${baseImage}?width=${width}`);

	useEffect(() => {
		const handleResize = () => {
			if (imageRef.current) {
				const image = imageRef.current as HTMLImageElement;
				let w = image.naturalWidth;

				if (w < 200) {
					w = 400;
				}

				if (fixedWidth) w = fixedWidth;

				setWidth(w);
			}
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, [fixedWidth]);

	useEffect(() => {
		if (imageRef.current)
			imageRef.current.src = `${baseImage}?width=${width}`;
		setImage(`${baseImage}?width=${width}`);
	}, [width, baseImage]);

	useEffect(() => {
		if (imageRef.current) imageRef.current.src = image;
	}, [image]);

	// @ts-expect-error ref type is not accepted
	return <img ref={imageRef} alt={""} {...props} />;
};

export default Image;
