/** @format */

import React, { useState } from "react";
import { baseUrl } from "../utils/variables";

interface ITopTileProps {
	title: string;
	imageUrl: string;
}
const TopTile: React.FC<ITopTileProps> = ({title, imageUrl}) => {
	const baseImage = `${baseUrl}/images/${imageUrl}?width=1200`;
	const [image] = useState<string>(baseImage);
	const [loading, setLoading] = useState(true);

	return (
		<div className="relative">
			<div className="absolute h-full w-full z-10 flex">
				<h1 className="m-auto text-white text-2xl lg:text-4xl font-bold font-playfair">
					{title}
				</h1>
			</div>
			<div
				className={`absolute h-full w-full ${
					!loading ? "bg-black" : ""
				} z-5 opacity-40 flex`}
			></div>
			<img
				src={image}
				alt=""
				className="max-h-96 w-full object-cover"
				onLoad={() => {
					setLoading(false);
				}}
			/>
		</div>
	);
};

export default TopTile;
