/** @format */
import React from "react";
import { useTranslation } from "react-i18next";
import { IRoom } from "./Rooms";

interface IRoomItemProps {
	room: IRoom;
	roomInx: number;
}

export const RoomItemSM: React.FC<IRoomItemProps> = ({ room }) => {
	const {t} = useTranslation("");
	return (
		<div className="w-full drop-shadow-sm border rounded-lg px-4 py-4 bg-secondary-50">
			<h2 className="text-lg font-medium text-gray-900 bg-secondary-50">
				{room.name}
			</h2>
			<div className="mt-1 text-sm text-gray-500  bg-secondary-50 flex flex-row gap-2">
				<div className={"font-bold"}>
					{t("accommodation.rooms.table.numberOfPeople")}:
				</div>{" "}
				{room.people}
			</div>

			<div className="mt-1 text-sm text-gray-500  bg-secondary-50 flex flex-row gap-2">
				<div className={"font-bold"}>
					{t("accommodation.rooms.table.priceLowSeason")}:
				</div>{" "}
				{room.low}
			</div>
			<div className="mt-1 text-sm text-gray-500  bg-secondary-50 flex flex-row gap-2">
				<div className={"font-bold"}>
					{t("accommodation.rooms.table.priceMidSeason")}:
				</div>{" "}
				{room.mid}
			</div>
			<div className="mt-1 text-sm text-gray-500  bg-secondary-50 flex flex-row gap-2">
				<div className={"font-bold"}>
					{t("accommodation.rooms.table.priceHighSeason")}:
				</div>{" "}
				{room.high}
			</div>
		</div>
	);
};
const RoomItem = ({room, roomInx}: IRoomItemProps) => {
	return (
		<tr key={roomInx} className="divide-x divide-gray-400  bg-secondary-50">
			<td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
				{room.name}
			</td>
			<td className="whitespace-nowrap p-4 text-sm text-gray-500">
				{room.people}
			</td>
			<td className="whitespace-nowrap p-4 text-sm text-gray-500">
				{room.low}
			</td>

			<td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
				{room.mid}
			</td>
			<td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0">
				{room.high}
			</td>
		</tr>
	);
};

export default RoomItem;
