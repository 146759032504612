/** @format */

import { Dialog, Disclosure, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { apiBaseUrl } from "../../utils/variables";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

const navigation = [
	{ name: "News", href: "/admin/news", current: true },
]

export const Admin: React.FC = () => {
	const [isValid, setIsValid] = useState(false);
	const [showLogin, setShowLogin] = useState(false);
	const [password, setPassword] = useState<string>("");
	const location = useLocation();


	// return <span>Path : {location.pathname}</span>

	const saveButtonRef = useRef(null);

	const login = async () => {
		console.log("login");
		const res = await fetch(`${apiBaseUrl}/auth/login`, {
			method: "POST",
			credentials: "include",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				password: password,
			}),
		});
		console.log("login2");
		if (res.ok) setShowLogin(false);
		else alert("Invalid password");
	};

	useEffect(() => {
		console.log(location.pathname);
		const valid = async () => {
			const res = await fetch(`${apiBaseUrl}/auth/valid`, {
				credentials: "include",
			});
			if (res.ok) {
				setIsValid(true);
			} else {
				setIsValid(false);
				setShowLogin(true);
			}
		};

		valid();
		// setActive();
	}, [location.pathname]);

	const close = () => {
		if (isValid) setShowLogin(false);
	};

	return (
		<>
			{/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-100">
        <body class="h-full">
        ```
      */}
			<div className="min-h-full">
				<div className="bg-secondary-400 pb-32">
					<Disclosure as="nav" className="bg-secondary-400 ">
						{({open}) => (
							<>
								<div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
									<div className="border-b border-secondary-800">
										<div className="flex h-16 items-center justify-between px-4 sm:px-0">
											<div className="flex items-center">
												<div className="flex-shrink-0">
													<img
														className="h-8 w-8"
														src="https://cascina-cipressa.hagenfaber.eu/images/Logos/full_margin_transparent_base.png?width=400"
														alt="Cascina Cipressa"
													/>
												</div>
												<div className="hidden md:block">
													<div className="ml-10 flex items-baseline space-x-4">
														{navigation.map(
															(item) => (
																<a
																	key={
																		item.name
																	}
																	href={
																		item.href
																	}
																	className={classNames("text-secondary-300 hover:bg-secondary-700 hover:text-white",
																		"rounded-md px-3 py-2 text-sm font-medium"
																	)}
																	aria-current={undefined}
																>
																	{item.name}
																</a>
															)
														)}
													</div>
												</div>
											</div>

											<div className="-mr-2 flex md:hidden">
												{/* Mobile menu button */}
												<Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-secondary-800 p-2 text-gray-400 hover:bg-secondary-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-secondary-800">
													<span className="sr-only">
														Open main menu
													</span>
													{open ? (
														<XMarkIcon
															className="block h-6 w-6"
															aria-hidden="true"
														/>
													) : (
														<Bars3Icon
															className="block h-6 w-6"
															aria-hidden="true"
														/>
													)}
												</Disclosure.Button>
											</div>
										</div>
									</div>
								</div>

								<Disclosure.Panel className="border-b border-gray-700 md:hidden">
									<div className="space-y-1 px-2 py-3 sm:px-3">
										{navigation.map((item) => (
											<Disclosure.Button
												key={item.name}
												as="a"
												href={item.href}
												className={classNames(
													item.current
														? "bg-secondary-900 text-white"
														: "text-secondary-300 hover:bg-secondary-700 hover:text-white",
													"block rounded-md px-3 py-2 text-base font-medium"
												)}
												aria-current={
													item.current
														? "page"
														: undefined
												}
											>
												{item.name}
											</Disclosure.Button>
										))}
									</div>
								</Disclosure.Panel>
							</>
						)}
					</Disclosure>
					<header className="py-10">
						<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
							<h1 className="text-3xl font-bold tracking-tight text-white">
								Dashboard
							</h1>
						</div>
					</header>
				</div>

				<main className="-mt-32">
					<div className="mx-auto max-w-7xl px-4 py-12 sm:px-6 lg:px-8 bg-white rounded-xl shadow">
						<Outlet />
					</div>
				</main>
			</div>
			<Transition.Root show={showLogin} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={saveButtonRef}
					onClose={close}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
									<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
										<div className="w-full">
											<div>
												<label
													htmlFor="email"
													className="block text-sm font-medium leading-6 text-gray-900"
												>
													Password
												</label>
												<div className="mt-2">
													<input
														value={password}
														onChange={(e) =>
															setPassword(
																e.target.value
															)
														}
														type="password"
														name="password"
														id="password"
														className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
													/>
												</div>
											</div>
										</div>
									</div>
									<div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md bg-tertiary-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
											onClick={() => login()}
											ref={saveButtonRef}
										>
											Login
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};
