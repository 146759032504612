/** @format */

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function getWeeksInMonth(
	year: number,
	month: number
): Array<Array<number | null>> {
	const weeks: Array<Array<number | null>> = [];
	const firstDayOfMonth = new Date(year, month, 1);
	const lastDayOfMonth = new Date(year, month + 1, 0);
	const currentDay = firstDayOfMonth;

	const paddingStart = new Array((firstDayOfMonth.getDay() + 6) % 7).fill(
		null
	);

	let week: Array<number | null> = [...paddingStart];

	while (currentDay <= lastDayOfMonth) {
		week.push(currentDay.getDate());

		if (currentDay.getDay() === 0) {
			// Sunday
			weeks.push(week);
			week = [];
		}

		currentDay.setDate(currentDay.getDate() + 1);
	}

	const paddingEnd = new Array(
		(7 - ((lastDayOfMonth.getDay() + 1) % 7)) % 7
	).fill(null);
	week.push(...paddingEnd);

	if (week.length > 0) {
		weeks.push(week);
	}

	return weeks;
}

interface ICheckInPickerProps {
	date: Date;
	setDate: (value: Date) => void;
	label: string;
}

const SingleDatePicker: React.FC<ICheckInPickerProps> = ({date, setDate, label}: ICheckInPickerProps) => {
	const {t} = useTranslation("");
	const months = t("contact.months", {defaultValue: [], returnObjects: true});
	const monthNamesTranslated: string[] = Object.values(months);
	const currentDate = new Date();
	const [month] = useState<number>(currentDate.getMonth());
	const [year] = useState<number>(currentDate.getFullYear());
	const weeks = getWeeksInMonth(year, month);
	const currentMonthName = monthNamesTranslated[month];

	const [show, setShow] = useState<boolean>(false);

	const previousMonth = () => {
	};

	const nextMonth = () => {
	};

	const dayOnclick = (day: number) => {
		const d = new Date(year, month, day);
		setDate(d);
	};

	return (
		<div className={"relative w-full"}>
			<div className="w-full flex-1 relative">
				<label
					htmlFor={"checkin"}
					className={"absolute bg-white -top-2 text-xs left-3 px-1"}
				>
					{label}
				</label>
				<input
					type="text"
					name={"checkin"}
					value={date.toLocaleDateString()}
					className={`outline-4 outline-primary-500 appearance-none bg-white cursor-pointer w-full h-10 indent-3 px-1 text-base text-gray-700 placeholder-gray-600 border border-gray-200 rounded-lg`}
					placeholder="Check out"
					// onFocus={() => {
					//     setShow(!show);
					// }}

					onClick={() => {
						setShow(!show);
					}}
					readOnly={true}
				/>
			</div>

			<div
				className={`${
					!show ? "hidden" : null
				} z-10 p-4 bg-white rounded shadow mt-1 absolute calendar flex flex-col justify-center items-center`}
			>
				<div className="">
					<div className="w-full flex justify-between items-center">
						<ChevronLeftIcon
							className="h-6 w-6"
							onClick={(e) => {
								e.preventDefault();
								previousMonth();
							}}
						/>
						<div className="font-bold text-lg text-center py-4 select-none">
							{currentMonthName} {year}
						</div>
						<div>
							<ChevronRightIcon
								className="h-6 w-6"
								onClick={(e) => {
									e.preventDefault();
									nextMonth();
								}}
							/>
						</div>
					</div>
					<div className="flex">
						<div className="text-sm text-gray-400 h-4 w-12 flex justify-center items-center">
							Mo
						</div>
						<div className="text-sm text-gray-400 h-4 w-12 flex justify-center items-center">
							Tu
						</div>
						<div className="text-sm text-gray-400 h-4 w-12 flex justify-center items-center">
							We
						</div>
						<div className="text-sm text-gray-400 h-4 w-12 flex justify-center items-center">
							Th
						</div>
						<div className="text-sm text-gray-400 h-4 w-12 flex justify-center items-center">
							Fr
						</div>
						<div className="text-sm text-gray-400 h-4 w-12 flex justify-center items-center">
							Sa
						</div>
						<div className="text-sm text-gray-400 h-4 w-12 flex justify-center items-center">
							Su
						</div>
					</div>
				</div>
				<div className="flex flex-col gap-1 mt-1">
					{weeks.map((week, weekIndex) => {
						return (
							<div key={weekIndex} className="flex rounded-full">
								{week.map((day, dayIndex) => {
									const heightWidth = "h-12 w-12";
									const classes = `flex justify-center items-center`;
									if (day === null) {
										return (
											<div
												className={`${classes} ${heightWidth}`}
												key={dayIndex}
											></div>
										);
									}

									let color = "";
									const d = new Date(year, month, day);

									if (date.getTime() === d.getTime()) {
										color += " bg-secondary-900 text-white";
									}

									return (
										<div
											key={dayIndex}
											className={`${heightWidth} ${classes}`}
										>
											<div
												className={`${color} flex justify-center items-center h-full w-full cursor-pointer text-sm border border-hidden hover:border-solid rounded-full`}
												onClick={(e) => {
													e.preventDefault();
													setShow(false);
													dayOnclick(day);
												}}
											>
												{day}
											</div>
										</div>
									);
								})}
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default SingleDatePicker;
