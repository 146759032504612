import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { apiBaseUrl, baseUrl } from '../../utils/variables';

export interface IGalleryImage {
    id: number;
    src: string;
    alt: string;
    category: string;
    className: string;
    loaded: boolean;
    ignore: boolean;
}
interface IGalleryState {
    showCarousel: boolean;
    selectedIndex: number;
    images: string[];
    APIBaseURL: string;
    baseURL: string;
}
const initialState: IGalleryState = {
    showCarousel: false,
    selectedIndex: 0,
    images: [],
    APIBaseURL: apiBaseUrl,
    baseURL: baseUrl,
}

const GallerySlice = createSlice({

    name: 'Gallery',
    initialState,
    reducers: {

        rotateCarousel: (state, action: PayloadAction<number>) => {
            state.selectedIndex = action.payload;
        },
        openCarousel: (state) => {
            state.showCarousel = true;
        },
        closeCarousel: (state) => {
            state.showCarousel = false;
        },
        setImages: (state, action: PayloadAction<string[]>) => {
            state.images = action.payload;
        }

    }
    // extra reducers
})

export const { openCarousel, closeCarousel, rotateCarousel, setImages } = GallerySlice.actions
export default GallerySlice.reducer
