/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import ImageTextSection from "../../components/ImageTextSection";
import TopTile from "../../components/TopTitle";
import Maps from "./Maps";

interface IExploreProps {}

const images1 = "WhatToDo/1";
const images2 = "WhatToDo/2";
const images3 = "WhatToDo/3";
const images4 = "WhatToDo/4";
const images5 = "WhatToDo/1";

const Explore: React.FC<IExploreProps> = () => {
	const { t } = useTranslation("");


	return (
		<div className="">
			<TopTile
				title={t("explore.title")}
				imageUrl="WhatToDo/main picture explore.jpg"
			/>
			<div className="flex justify-center">
				<div className="flex-grow max-w-6xl px-6 py-6 lg:px-8 lg:py-8">
					<ImageTextSection
						gallery={true}
						imagePath={images1}
						imgRight={false}
						text={t("explore.1")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images2}
						imgRight={true}
						text={t("explore.2")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images3}
						imgRight={false}
						text={t("explore.3")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images4}
						imgRight={true}
						text={t("explore.4")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images5}
						imgRight={false}
						text={t("explore.5")}
					/>

					<div className="w-full">
						<Maps />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Explore;
