/** @format */

import { useTranslation } from "react-i18next";

import React, { useEffect, useState } from "react";
import Sharing from "../../components/Sharing";
import { apiBaseUrl, baseUrl } from "../../utils/variables";
import { IArticle } from "../admin/news/NewsAdmin";

export interface ITitleDescription {
	id: string;
	title: string;
	description: string;
	content: string;
}

export interface IContent {
	[key: string]: ITitleDescription;
}

export interface INewsItem {
	id: string;
	created: string;
	data: IContent;
}

const News: React.FC = () => {
	const {t, i18n} = useTranslation("");
	const [news, setNews] = useState<INewsItem[]>([]);

	useEffect(() => {
		const getNews = async () => {
			const response = await fetch(`${apiBaseUrl}/news`);
			const items = (await response.json()).map(
				(article: IArticle) => {
					return {
						id: article.id,
						created: article.created,
						data: {
							en: {
								id: article.en.id,
								title: article.en.title,
								description: article.en.description,
								content: article.en.content,
							},
							de: {
								id: article.de.id,
								title: article.de.title,
								description: article.de.description,
								content: article.de.content,
							},
							it: {
								id: article.it.id,
								title: article.it.title,
								description: article.it.description,
								content: article.it.content,
							},
							fr: {
								id: article.fr.id,
								title: article.fr.title,
								description: article.fr.description,
								content: article.fr.content,
							},
							nl: {
								id: article.nl.id,
								title: article.nl.title,
								description: article.nl.description,
								content: article.nl.content,
							},
						},
					};
				}
			);
			setNews(items);
		};

		getNews();
	}, []);

	return (
		<div className="">
			<div className="flex justify-center">
				<div className="flex-grow max-w-6xl px-6 py-6 lg:px-8 lg:py-8">
					<div className="flex flex-col gap-6">
						{news
							.sort((a, b) => {
								return (
									new Date(b.created).valueOf() -
									new Date(a.created).valueOf()
								);
							})
							.map((item, index) => {
								return (
									<div className={"flex"} key={index}>
										<div
											key={item.id}
											className="flex-1 cursor-default"
										>
											<h2 className={"text-xl font-bold"}>
												{item.data[i18n.language].title}
											</h2>
											<div className="">
												{
													item.data[i18n.language]
														.description
												}
											</div>
											<a
												href={`/news/${item.id}`}
												className={"underline"}
											>
												{t("news.show-more")}
											</a>
										</div>
										<Sharing
											url={`${baseUrl}/news/${item.id}`}
										/>
									</div>
								);
							})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default News;
