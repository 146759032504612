/** @format */

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React, { Fragment } from "react";

import { useAppDispatch, useAppSelector } from "../../app/hooks";

import { ILocale } from "./language.interface";
import { setLocale } from "./language.slice";

import languageConfig from "../language/language.config";

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

const Language: React.FC = () => {
	const changeLanguage = (lng: string) => {
		languageConfig.changeLanguage(lng);
	};
	const localeState = useAppSelector((state) => state.locales);
	const dispatch = useAppDispatch();

	const getSelected = () => {
		return localeState.locales.find(
			(locale: ILocale) => locale.id === localeState.selectedLocale
		);
	};
	const selected = getSelected();

	const setSelected = (l: ILocale) => {
		dispatch(setLocale(l.id));
		changeLanguage(l.id);
	};

	return (
		<Listbox value={selected} onChange={setSelected}>
			{({open}) => (
				<>
					<div className="relative">
						<Listbox.Button className="relative w-full cursor-default rounded-md border border-gray-300 bg-secondary-50 py-2 pl-4 pr-10 text-left shadow-sm focus:border-lime-700 focus:outline-none focus:ring-1 focus:ring-lime-700 sm:text-sm">
							<span className="block truncate">
								<span className="mr-1.5">
									{selected ? selected.icon : ""}
								</span>
								{selected ? selected.short : ""}
							</span>
							<span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
								<ChevronUpDownIcon
									className="h-5 w-5 text-gray-400"
									aria-hidden="true"
								/>
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={Fragment}
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-secondary-50 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
								{localeState.locales.map((locale: ILocale) => (
									<Listbox.Option
										key={locale.id}
										className={({active}) =>
											classNames(
												active
													? "text-white bg-tertiary-600"
													: "text-gray-900",
												"relative cursor-default select-none py-2 pl-3 pr-9 transition-colors"
											)
										}
										value={locale}
									>
										{({selected, active}) => (
											<>
												<span
													className={classNames(
														selected
															? "font-semibold"
															: "font-normal",
														"block truncate"
													)}
												>
													<span className="mr-2">
														{locale.icon}
													</span>
													{locale.short}
												</span>

												{selected ? (
													<span
														className={classNames(
															active
																? "text-white"
																: "text-tertiary-900",
															"absolute inset-y-0 right-0 flex items-center pr-4"
														)}
													>
														<CheckIcon
															className="h-5 w-5"
															aria-hidden="true"
														/>
													</span>
												) : null}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</>
			)}
		</Listbox>
	);
};

export default Language;
