/** @format */
import React, { useEffect, useState } from "react";
import { apiBaseUrl } from "../../utils/variables";
import Image from "./image";

const Gallery: React.FC = () => {
	const [images, setImages] = useState<string[]>([]);

	useEffect(() => {
		const fetchImages = async () => {
			const response = await fetch(`${apiBaseUrl}/image-list`);
			const data = await response.json();

			setImages(data);
		};
		fetchImages();
	}, []);

	return (
		<div className="flex justify-center">
			<div className="max-w-6xl w-full px-6 py-6 lg:px-8 lg:py-8">
				<div className="lg:flex gap-2 hidden w-full">
					<div className="flex-1 column flex flex-col gap-2">
						{images.map((image, index) => {
							if (index % 3 === 0)
								return (
									<Image
										key={index}
										index={index}
										src={image}
										alt={"test"}
									/>
								);
							else return null;
						})}
					</div>
					<div className="flex-1 column flex flex-col gap-2">
						{images.map((image, index) => {
							if (index % 3 === 1)
								return (
									<Image
										key={index}
										index={index}
										src={image}
										alt={"test"}
									/>
								);
							else return null;
						})}
					</div>
					<div className="flex-1 column flex flex-col gap-2">
						{images.map((image, index) => {
							if (index % 3 === 2)
								return (
									<Image
										key={index}
										index={index}
										src={image}
										alt={"test"}
									/>
								);
							else return null;
						})}
					</div>
				</div>
				<div className="gap-2 sm:flex lg:hidden hidden">
					<div className="column flex flex-col gap-2">
						{images.map((image, index) => {
							if (index % 2 === 0)
								return (
									<Image
										key={index}
										index={index}
										src={image}
										alt={"test"}
									/>
								);
							else return null;
						})}
					</div>
					<div className="column flex flex-col gap-2">
						{images.map((image, index) => {
							if (index % 2 === 1)
								return (
									<Image
										key={index}
										index={index}
										src={image}
										alt={"test"}
									/>
								);
							else return null;
						})}
					</div>
				</div>
				<div className="gap-2 flex sm:hidden">
					<div className="column flex flex-col gap-2">
						{images.map((image, index) => {
							return (
								<Image
									key={index}
									index={index}
									src={image}
									alt={"test"}
								/>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Gallery;
