import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import backend from "i18next-http-backend";



i18n
    .use(backend)
    .use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: "en",
        fallbackLng: "en",
        initImmediate: false,
        keySeparator: ".", // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
