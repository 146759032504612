import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const BookNowButton: React.FC = () => {
	const {t} = useTranslation("");

	return (
		<Link
			to={"/contact"}
			className="text-sm font-semibold leading-6 py-2 px-4 transition-colors bg-primary-400 hover:bg-primary-500 rounded-md text-white"
		>
			{t("navigation.book")}
		</Link>

	)
}
