import { Status, Wrapper } from "@googlemaps/react-wrapper";
import React, { useEffect, useRef } from "react";
import { Root, createRoot } from "react-dom/client";


const center = {lat: 43.67532232686335, lng: 10.772743168180241};
const markers = [
	{
		position: {lat: 43.72349332285225, lng: 10.400183928876746},
		popup: "Pisa",
        color: "green"
	},
	{
		position: {lat: 43.84111149090102, lng: 10.502217482852364},
		popup: "Lucca",
        color: "green"
	},
	{
		position: {lat: 43.769871, lng: 11.255576},
		popup: "Firenze",
        color: "green"
	},
	{
		position: {lat: 43.467636, lng: 11.043733},
		popup: "San Gimignano",
        color: "green"
	},
	{
		position: {lat: 43.401666, lng: 10.859444},
		popup: "Volterra",
        color: "green"
	},
	{
		position: {lat: 43.890833, lng: 10.777778},
		popup: "Montecatini Terme",
        color: "green"
	},
	{
		position: {lat: 43.959167, lng: 10.165833},
		popup: "Forte dei Marmi",
        color: "green"
	},
	{
		position: {lat: 43.683333, lng: 10.866667},
		popup: "San Miniato",
        color: "green"
	},
	{
		position: {lat: 43.783333, lng: 10.916667},
		popup: "Vinci",
        color: "green"
	},
	{
		position: {lat: 43.666667, lng: 10.283333},
		popup: "Marina di Pisa",
        color: "green"
	},
	{
		position: {lat: 44.116667, lng: 10.166667},
		popup: "Parco Alpi Apuani",
        color: "green"
	},
];


const render = (status: Status): React.ReactElement => {
  if (status === Status.FAILURE) return <span>Error while loading map</span>;
  return <span>loading</span>;
};

const Maps: React.FC = () => (
    <Wrapper apiKey={"AIzaSyB2sHrzy2scSG6XzOeSMO0P3CDb5D9n-Wk"} render={render} libraries={["marker"]}>
        <MyMap />
    </Wrapper>
);

const MyMap: React.FC = () => {

    const ref = useRef<HTMLDivElement | null>(null);
    const [map, setMap] = React.useState<google.maps.Map | null>(null);

    useEffect(() => {
        if (ref.current) {
            setMap(new window.google.maps.Map(ref.current, {
                center: center,
                zoom: 9,
                mapId: "b1f0f0f0f0f0f0f0",
                disableDefaultUI: true,
                zoomControl: true,
            }));
        }

    }, []);

    return (
        <>
            <div id="google-maps-id" ref={ref} className="w-full h-96 rounded-lg drop-shadow-sm" />
            {map && (
                <>
                    {markers.map((marker, index) => <Marker key={index} map={map} marker={marker} />)}
                    <Marker key={99} map={map} marker={{
                        position: center,
                        popup: "Cascina Cipressa"
                    }} />
                </>
            )}
        </>
    )

}

interface IMarker {
    map: google.maps.Map;
    marker: {
        position: google.maps.LatLngLiteral;
        popup: string;
        color?: string;
    }
}

const Marker: React.FC<IMarker> = ({ map, marker }) => {
    
    const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(null);
    const rootRef = useRef<Root | null>(null);

    const renderMarker = () => {
            const pinBackground = new window.google.maps.marker.PinElement({
                background: marker.color === "green" ? "#4CAF50" : undefined,
                borderColor: marker.color === "green" ? "#388E3C" : undefined,
                glyphColor: marker.color === "green" ? "#388E3C" : undefined,
            });


            markerRef.current = new window.google.maps.marker.AdvancedMarkerElement({
                map: map,
                position: marker.position,
                title: marker.popup,
                content: pinBackground.element
            });
    }

    useEffect(() => {
        if (!rootRef.current && map) {
            const container = document.createElement("div");
            rootRef.current = createRoot(container);

            renderMarker();
       }
    }, []);

    useEffect(() => {

        if (rootRef.current) {


            renderMarker();
        }
    }, [map, marker]);
    
    return <></>
}

export default Maps;
