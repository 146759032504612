/** @format */

import React from "react";
import { useTranslation } from "react-i18next";

const Address: React.FC = () => {
	const {t} = useTranslation("");

	return (
		<a className="h-full w-full flex flex-col justify-center " target={"_blank"} rel={"noreferrer"} href={"https://www.google.com/maps/place/Agriturismo+Cascina+Cipressa/@43.6752642,10.7703292,17z/data=!4m13!1m2!2m1!1sCIPRESSA+SOCIETA%E2%80%99+AGRICOLA+S.r.L.+Via+Vaghera+25,+56028+San+Miniato,+Pisa,+Italy!3m9!1s0x132a71916803d807:0x9c8ae4e6d14c693!5m2!4m1!1i2!8m2!3d43.6752603!4d10.7729041!15sClJDSVBSRVNTQSBTT0NJRVRB4oCZIEFHUklDT0xBIFMuci5MLiBWaWEgVmFnaGVyYSAyNSwgNTYwMjggU2FuIE1pbmlhdG8sIFBpc2EsIEl0YWx5kgEIZmFybXN0YXngAQA!16s%2Fg%2F1tgfmc9d?entry=ttu"}>
			<h2 className={"font-bold"}>{t("info.address.title")}</h2>
			<p className={"flex flex-col text-primary-500 hover:text-primary-600 underline underline-offset-2"}>
				<span>CIPRESSA SOCIETA’ AGRICOLA S.r.L.</span>
				<span>Via Vaghera 25,</span>
				<span>56028 San Miniato, Pisa, Italy</span>
				<span>43°40’30.3”N10°46’21.9”E</span>
			</p>
		</a>
	);
}

export default Address;
