/** @format */
import React from "react";
import { useTranslation } from "react-i18next";
import ImageTextSection from "../../components/ImageTextSection";
import TopTile from "../../components/TopTitle";

const images1 = "Restaurant/1";
const images2 = "Restaurant/2";
const images3 = "Restaurant/3";
const images4 = "Restaurant/4";
const images5 = "Restaurant/5";

interface IRestaurantProps {}
const Restaurant: React.FC<IRestaurantProps> = () => {
	const {t} = useTranslation("");

	return (
		<div className="">
			<TopTile
				title={t("restaurant.title")}
				imageUrl="Restaurant/overview_restaurant.jpg"
			/>

			<div className="flex justify-center">
				<div className="flex-grow max-w-6xl px-6 py-6 lg:px-8 lg:py-8">
					<ImageTextSection
						gallery={true}
						imagePath={images1}
						imgRight={false}
						text={t("restaurant.1")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images2}
						imgRight={true}
						text={t("restaurant.2")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images3}
						imgRight={false}
						text={t("restaurant.3")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images4}
						imgRight={true}
						text={t("restaurant.4")}
					/>
					<ImageTextSection
						gallery={true}
						imagePath={images5}
						imgRight={false}
						text={t("restaurant.5")}
					/>
				</div>
			</div>
		</div>
	);
};

export default Restaurant;
