/** @format */
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import React, { Suspense } from "react";
import { ClipLoader } from "react-spinners";
import GalleryCarousel from "./components/gallery/GalleryCarousel";
import { Admin } from "./features/admin/Admin";
import { BookingAdmin } from "./features/admin/bookings/BookingAdmin";
import ArticleEditor from "./features/admin/news/ArticleEditor";
import { NewsAdmin } from "./features/admin/news/NewsAdmin";
import { RoomsAdmin } from "./features/admin/rooms/RoomsAdmin";
import BookContact from "./features/contact/Contact";
import Explore from "./features/explore/Explore";
import Gallery from "./features/gallery/gallery";
import Home from "./features/home/home";
import Info from "./features/info/Info";
import SingleArticle from "./features/news/SingleArticle";
import News from "./features/news/news";
import OurProducts from "./features/ourProducts/OurProducts";
import Restaurant from "./features/restaurant/Restaurant";
import Rooms from "./features/rooms/Rooms";
import Welcome from "./features/welcome/welcome";

const Router: React.FC = () => {
	return (
		<div className={``}>
			<Suspense
				fallback={
					<div
						className={`w-full h-full flex justify-center items-center`}
					>
						<ClipLoader color="#000" loading={true} size={50} />
					</div>
				}
			>
				<BrowserRouter>
					<Routes>
						<Route path="/" element={<Home />}>
							<Route index element={<Welcome />} />
							<Route path="gallery" element={<Gallery />} />
							<Route path="accomodation" element={<Rooms />} />
							<Route path="restaurant" element={<Restaurant />} />
							<Route path="explore" element={<Explore />} />
							<Route path="contact">
								<Route index element={<BookContact />} />
							</Route>
							<Route path="info" element={<Info />} />
							<Route path="products" element={<OurProducts />} />
							<Route path="news">
								<Route index element={<News />} />
								<Route path=":id" element={<SingleArticle />} />
							</Route>
							<Route path="*" element={<Navigate to={"/"} />} />
						</Route>
						<Route path={"admin"} element={<Admin />}>
							<Route index element={<Navigate to={"news"} />} />
							<Route path={"news"}>
								<Route index element={<NewsAdmin />} />
								<Route
									path=":articleId"
									element={<ArticleEditor />}
								/>
							</Route>
							<Route path={"rooms"} element={<RoomsAdmin />} />
							<Route
								path={"bookings"}
								element={<BookingAdmin />}
							/>
							<Route path={"*"} element={<Navigate to={"/"} />} />
						</Route>
					</Routes>
				</BrowserRouter>
				<GalleryCarousel />
			</Suspense>
		</div>
	);
};

export default Router;
