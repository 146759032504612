/** @format */

import { useTranslation } from "react-i18next";

import React from "react";
import Address from "./Address";
import SimpleSchedule from "./SimpleSchedule";
import TelVATEmail from "./TelVATEmail";

const Info: React.FC = () => {
	const {t} = useTranslation("");

	return (
		<div className="">
			<div className="flex justify-center">
				<div className="flex flex-col max-w-6xl px-6 py-6 lg:px-8 lg:py-8">
					<div className={"flex flex-col lg:flex-row gap-4"}>
						<div className={"flex-1 p-4"}>
							<Address />
						</div>
						<div className={"flex-1 p-4"}>
							<TelVATEmail />
						</div>
						<div className={"flex-1 p-4"}>
							<SimpleSchedule />
						</div>
					</div>

					{/*<Schedule />*/}

					<div className={"pt-4 leading-loose"}>
						<h2 className={"font-bold text-xl"}>
							{t("info.terms_conditions.title")}:
						</h2>
						<p className={"tracking-wide"}>
							{t("info.terms_conditions.text")}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Info;
