/** @format */

import React, { useEffect, useRef, useState } from "react";

import { apiBaseUrl, baseUrl } from "../utils/variables";
import Carousel from "./Carousel";

interface IImageTextSectionProps {
	imageURL?: string;
	gallery: boolean;
	text: string;
	imgRight: boolean;
	imagePath?: string;
}

const ImageTextSection: React.FC<IImageTextSectionProps> = ({
	text,
	imgRight,
	gallery = false,
	imageURL = "DomainViews/1/a.jpg",
	imagePath = "",
}) => {
	const [width, setWidth] = useState<number>(800);
	const baseImage = `${baseUrl}/images/${imageURL}`;
	const [image] = useState<string>(baseImage);
	const imageRef = useRef(null);

	const [images, setImages] = useState<string[]>([]);

	useEffect(() => {
		const handleResize = () => {
			if (imageRef.current) {
				const image = imageRef.current as HTMLImageElement;
				setWidth(image.clientWidth);
			}
		};
		const getImages = async () => {
			const res = await fetch(`${apiBaseUrl}/image-list/${imagePath}`);
			const data = await res.json();

			const tempImages: string[] = [];

			// console.table(data);
			for (const image of data) {
				tempImages.push(`${image}`);
			}

			setImages(tempImages);
		};
		if (gallery) {
			getImages();
		}
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		}
	}, [gallery, imagePath]);

	useEffect(() => {
		// setImage(`${baseImage}?width=${width}`);
	}, []);

	return (
		<div
			className={`w-full py-2 flex lg:items-center gap-2 lg:gap-8 flex-col ${
				imgRight ? "lg:flex-row" : "lg:flex-row-reverse"
			} `}
		>
			<div className="flex-1 lg:p-12">{text}</div>

			<div className="flex-1 col-span-8 flex justify-center items-center">
				{gallery && images ? (
					<Carousel images={images} auto={true} showArrows={false} />
				) : (
					<img
						ref={imageRef}
						width={width}
						src={image}
						alt="Some"
						className={`cursor-default rounded-xl transition-all duration-100 drop-shadow-md`}
					/>
				)}
			</div>
		</div>
	);
};

export default ImageTextSection;
