import { XMarkIcon } from '@heroicons/react/20/solid';
import React, { useState } from "react";
import { Link } from "react-router-dom";

const Banner: React.FC = () => {
    const [bannerOpen, setBannerOpen] = useState(true);

    const closeClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        setBannerOpen(false);
    }
    return (
        <div className={`${bannerOpen ? "flex" : "hidden"} items-center gap-x-6 bg-secondary-600 px-6 py-2.5 sm:px-3.5 sm:before:flex-1`}>
            <p className="text-sm leading-6 text-white">
                <Link to={"/news"} >
                    <strong className="font-semibold">Cascina Cipressa</strong>
                    <svg viewBox="0 0 2 2" className="mx-2 inline h-0.5 w-0.5 fill-current" aria-hidden="true">
                        <circle cx={1} cy={1} r={1} />
                    </svg>
                    &nbsp;<span aria-hidden="true">News</span>
                </Link>
            </p>
            <div className="flex flex-1 justify-end">
                <button type="button" className="-m-3 p-3 focus-visible:outline-offset-[-4px]" onClick={closeClick}>
                    <span className="sr-only">Dismiss</span>
                    <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
                </button>
            </div>
        </div>
    )
}

export default Banner;
