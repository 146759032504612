/** @format */

import {
	EmailIcon,
	EmailShareButton,
	FacebookIcon,
	FacebookShareButton,
	XIcon,
	TwitterShareButton,
	WhatsappIcon,
	WhatsappShareButton
} from "react-share";

import { Dialog, Transition } from "@headlessui/react";
import { ShareIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";

interface ISharing {
	url: string;
}
const Sharing: React.FC<ISharing> = ({url}) => {
	const [share, setShare] = useState<boolean>(false);
	return (
		<div className={"flex gap-1"}>
			<button onClick={() => setShare(true)}>
				<ShareIcon className="h-5 w-5" aria-hidden="true" />
			</button>

			<Transition.Root show={share} as={Fragment}>
				<Dialog as="div" className="relative z-10" onClose={setShare}>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
									<div className={"flex gap-1"}>
										<FacebookShareButton
											url={url}
											title={"Check out the Cascina Cipressa article"}
											hashtag="#cascinacipressa"
										>
											<FacebookIcon size={32} round />
										</FacebookShareButton>
										<EmailShareButton
											url={url}
											subject={
												"Check out the Cascina Cipressa article"
											}
											body={
												"Cascina Cipressa has a new news article! Check it out here: "
											}
										>
											<EmailIcon size={32} round />
										</EmailShareButton>
										<TwitterShareButton
											url={url}
											title={"Check out the Cascina Cipressa article"}
										>
											<XIcon size={32} round />
										</TwitterShareButton>
										<WhatsappShareButton
											url={url}
											title={"Check out the Cascina Cipressa article"}
										>
											<WhatsappIcon size={32} round />
										</WhatsappShareButton>

									</div>
									<div className="mt-5 sm:mt-6">
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
											onClick={() => setShare(false)}
										>
											Done
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	);
};

export default Sharing;
