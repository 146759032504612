// create a simple rtk slice

import { createSlice, } from '@reduxjs/toolkit';
import { } from '../../app/store';

// Define a type for the slice state
interface BookState {
    
}

// Define the initial state using that type
const initialState: BookState = {

};

export const bookSlice = createSlice({
    name: 'book',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {

    },
});


// Other code such as selectors can use the imported `RootState` type
export default bookSlice.reducer;
