/** @format */

import MDEditor from "@uiw/react-md-editor";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import rehypeSanitize from "rehype-sanitize";
import { apiBaseUrl } from "../../../utils/variables";
import { INewsItem } from "../../news/news";

interface ITabItem {
	id: number;
	name: string;
	current: boolean;
	short: string;
}

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(" ");
}

const ArticleEditor: React.FC = () => {
	const {articleId} = useParams();

	const [article, setArticle] = useState<INewsItem>({
		id: "0",
		created: new Date().toUTCString(),
		data: {},
	});

	const [tabs, setTabs] = useState<ITabItem[]>([
		{id: 0, name: "English", current: true, short: "en"},
		{id: 1, name: "Italian", current: false, short: "it"},
		{id: 2, name: "French", current: false, short: "fr"},
		{id: 3, name: "German", current: false, short: "de"},
		{id: 4, name: "Dutch", current: false, short: "nl"},
	]);
	const [orgValue, setOrgValue] = useState("");
	const [value, setValue] = useState("");

	const [title, setTitle] = useState("");
	const [orgTitle, setOrgTitle] = useState("");

	const [desc, setDesc] = useState("");
	const [orgDesc, setOrgDesc] = useState("");

	const tabChange = (tab: ITabItem) => {
		const tempTabs = [...tabs];
		const currentTab = tabs.find((t) => t.current);
		if (currentTab) {
			tempTabs[currentTab.id].current = false;
			tempTabs[tab.id].current = true;
			setTabs(tempTabs);
		}
	};

	const getArticle = async () => {
		const response = await fetch(`${apiBaseUrl}/news/${articleId}`);
		const a = await response.json();
		const currentLang = tabs.find((tab) => tab.current)?.short;
		if (currentLang) {
			setArticle({
				id: a.id,
				created: a.created,
				data: {
					en: {
						id: a.en.id,
						title: a.en.title,
						description: a.en.description,
						content: a.en.content,
					},
					de: {
						id: a.de.id,
						title: a.de.title,
						description: a.de.description,
						content: a.de.content,
					},
					it: {
						id: a.it.id,
						title: a.it.title,
						description: a.it.description,
						content: a.it.content,
					},
					fr: {
						id: a.fr.id,
						title: a.fr.title,
						description: a.fr.description,
						content: a.fr.content,
					},
					nl: {
						id: a.nl.id,
						title: a.nl.title,
						description: a.nl.description,
						content: a.nl.content,
					},
				},
			});
		}
	};

	useEffect(() => {
		getArticle();
	}, []);

	useEffect(() => {
		const currentTab = tabs.find((t) => t.current);
		if (currentTab) {
			setOrgValue(article.data[currentTab.short]?.content);
			setValue(article.data[currentTab.short]?.content);

			setOrgTitle(article.data[currentTab.short]?.title);
			setTitle(article.data[currentTab.short]?.title);

			setOrgDesc(article.data[currentTab.short]?.description);
			setDesc(article.data[currentTab.short]?.description);
		}
	}, [tabs, article.data]);

	useEffect(() => {
		const currentLang = tabs.find((tab) => tab.current)?.short;
		if (currentLang) {
			setOrgValue(article.data[currentLang]?.content);
			setValue(article.data[currentLang]?.content);

			setOrgTitle(article.data[currentLang]?.title);
			setTitle(article.data[currentLang]?.title);

			setOrgDesc(article.data[currentLang]?.description);
			setDesc(article.data[currentLang]?.description);
		}
	}, [tabs, article]);

	const save = async () => {
		const currentLang = tabs.find((tab) => tab.current)?.short;
		if (currentLang) {
			await fetch(`${apiBaseUrl}/news/${article.data[currentLang].id}`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
				},
				credentials: "include",
				body: JSON.stringify({
					content: value,
					title: title,
					description: desc,
				}),
			});

			await getArticle();
		}
	};

	const cancel = () => {
		setValue(orgValue);
		setDesc(orgDesc);
		setTitle(orgTitle);
	};

	const isEnabled =
		orgValue === value && orgTitle === title && orgDesc === desc;

	return (
		<>
			<div className="border-b border-gray-200 py-5 sm:pb-0">
				<div className="">
					<div className="sm:hidden">
						<label htmlFor="current-tab" className="sr-only">
							Select a tab
						</label>
						<select
							id="current-tab"
							name="current-tab"
							className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-tertiary-500 focus:outline-none focus:ring-tertiary-500 sm:text-sm"
							value={tabs.find((tab) => tab.current)?.name}
						>
							{tabs.map((tab) => (
								<option key={tab.name}>{tab.name}</option>
							))}
						</select>
					</div>
					<div className="hidden sm:block">
						<div className="-mb-px flex space-x-8">
							{tabs.map((tab) => (
								<button
									key={tab.name}
									className={classNames(
										tab.current
											? "border-tertiary-500 text-tertiary-600"
											: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
										"whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
									)}
									aria-current={
										tab.current ? "page" : undefined
									}
									onClick={() => tabChange(tab)}
								>
									{tab.name}
								</button>
							))}
						</div>
					</div>
				</div>
			</div>
			<form>
				<div>
					<label
						htmlFor="title"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						Title
					</label>
					<div className="mt-2">
						<input
							value={title}
							onChange={(e: React.FormEvent<HTMLInputElement>) => {
								setTitle(e.currentTarget.value);
							}}
							type="text"
							name="title"
							id="title"
							className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						/>
					</div>
				</div>
				<div>
					<label
						htmlFor="desc"
						className="block text-sm font-medium leading-6 text-gray-900"
					>
						Description
					</label>
					<div className="mt-2">
						<input
							value={desc}
							onChange={(e: React.FormEvent<HTMLInputElement>) => {
								setDesc(e.currentTarget.value);
							}}
							type="text"
							name="desc"
							id="desc"
							className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
						/>
					</div>
				</div>
			</form>

			<div className="pt-5" data-color-mode="light">
				<MDEditor
					value={value}
					onChange={(value = "") => {
						setValue(value);
					}}
					previewOptions={{
						rehypePlugins: [[rehypeSanitize]],
					}}
				/>
			</div>
			<div className="pt-5 flex justify-end gap-4">
				<button
					type="button"
					disabled={isEnabled}
					className={`disabled:bg-gray-400 rounded-md bg-gray-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600`}
					onClick={() => cancel()}
				>
					Cancel
				</button>
				<button
					type="button"
					disabled={isEnabled}
					className={`disabled:bg-gray-400 rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600`}
					onClick={() => save()}
				>
					Save
				</button>
			</div>
		</>
	);
}

export default ArticleEditor;
