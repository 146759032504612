/** @format */
import React from "react";
import { useTranslation } from "react-i18next";

const TelVATEmail: React.FC = () => {
  const { t } = useTranslation("");

  return (
    <div className="h-full w-full flex flex-col justify-center">
      <div className={"flex flex-row"}>
        <div className={"font-bold"}>{t("info.vat")}:</div>&nbsp;PIVA
        02449890504
      </div>
      <div className={"flex flex-row"}>
        <div className={"font-bold"}>{t("info.email")}:</div>&nbsp;
        <a
          href="mailto:booking@cascinacipressa.com"
          className={
            "text-primary-500 hover:text-primary-600 underline underline-offset-2"
          }
        >
          booking@cascinacipressa.com
        </a>
      </div>
      <div className={"flex flex-row"}>
        <div className={"font-bold"}>{t("info.phone")}:</div>&nbsp;
        <a
          href="tel:+393505289704"
          className={
            "text-primary-500 hover:text-primary-600 underline underline-offset-2"
          }
        >
          +39 350 5289704
        </a>
      </div>
    </div>
  );
};

export default TelVATEmail;
