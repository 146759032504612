/** @format */

import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import React, { Fragment, useEffect, useRef, useState } from "react";

import { apiBaseUrl } from "../../../utils/variables";
import { INewsItem } from "../../news/news";

export interface IArticle {
	id: string;
	created: string;
	en: {
		id: string;
		title: string;
		description: string;
		content: string;
	};
	de: {
		id: string;
		title: string;
		description: string;
		content: string;
	};
	it: {
		id: string;
		title: string;
		description: string;
		content: string;
	};
	fr: {
		id: string;
		title: string;
		description: string;
		content: string;
	};
	nl: {
		id: string;
		title: string;
		description: string;
		content: string;
	};
}

export const NewsAdmin: React.FC = () => {
	const [news, setNews] = useState<INewsItem[]>([]);
	const [open, setOpen] = useState(false);
	const cancelButtonRef = useRef(null);
	const [delArticle, setDelArticle] = useState<string>("");

	const getNews = async () => {
		const response = await fetch(`${apiBaseUrl}/news`);
		const items = (await response.json()).map(
			(article: IArticle) => {
				return {
					id: article.id,
					created: article.created,
					data: {
						en: {
							title: article.en.title,
							description: article.en.description,
							content: article.en.content,
						},
						de: {
							title: article.de.title,
							description: article.de.description,
							content: article.de.content,
						},
						it: {
							title: article.it.title,
							description: article.it.description,
							content: article.it.content,
						},
						fr: {
							title: article.fr.title,
							description: article.fr.description,
							content: article.fr.content,
						},
						nl: {
							title: article.nl.title,
							description: article.nl.description,
							content: article.nl.content,
						},
					},
				};
			}
		);
		items.sort((a: IArticle, b: IArticle) => {
			const x = new Date(a.created),
				y = new Date(b.created);
			if (x > y) {
				return 1;
			}
			if (x < y) {
				return -1;
			}
			return 0;
		});
		setNews(items);
	};

	useEffect(() => {
		getNews();
	}, []);

	const addEmptyArticle = async () => {
		await fetch(`${apiBaseUrl}/news`, {
			method: "POST",
			credentials: "include",
		});
		getNews();
	};

	const deleteArticle = async (id: string) => {
		setDelArticle(id);
		setOpen(true);
	};

	const confirmDelete = async () => {
		await fetch(`${apiBaseUrl}/news/${delArticle}`, {
			method: "DELETE",
			credentials: "include",
		});
		getNews();
		setOpen(false);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-8">
			<div className="sm:flex sm:items-center">
				<div className="sm:flex-auto">
					<h1 className="text-base font-semibold leading-6 text-gray-900">
						Articles
					</h1>
					<p className="mt-2 text-sm text-gray-700">
						A list of all the articles with their English title,
						description and date of creation.
					</p>
				</div>
				<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
					<button
						type="button"
						className="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
						onClick={() => addEmptyArticle()}
					>
						Add article
					</button>
				</div>
			</div>
			<div className="mt-8 flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
						<div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
										>
											Title
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Description
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Created
										</th>

										<th
											scope="col"
											className="relative py-3.5 pl-3 pr-4 sm:pr-6"
										>
											<span className="sr-only">
												Edit
											</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{news.map((article, index) => {
										return (
											<tr key={index}>
												<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
													{article.data["en"].title}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{
														article.data["en"]
															.description
													}
												</td>
												<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
													{new Date(
														article.created
													).toLocaleDateString()}
												</td>

												<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 flex gap-2">
													<a
														href={`/admin/news/${article.id}`}
														className="text-primary-600 hover:text-primary-900"
													>
														Edit
													</a>
													<button
														className="text-red-600 hover:text-red-900"
														onClick={() =>
															deleteArticle(
																article.id
															)
														}
													>
														Delete
													</button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
			<Transition.Root show={open} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-10"
					initialFocus={cancelButtonRef}
					onClose={setOpen}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<Transition.Child
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
									<div className="sm:flex sm:items-start">
										<div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
											<ExclamationTriangleIcon
												className="h-6 w-6 text-red-600"
												aria-hidden="true"
											/>
										</div>
										<div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
											<Dialog.Title
												as="h3"
												className="text-base font-semibold leading-6 text-gray-900"
											>
												Delete article
											</Dialog.Title>
											<div className="mt-2">
												<p className="text-sm text-gray-500">
													Are you sure you want to
													delete this article? All of
													the related data will be
													permanently removed from the
													database servers forever.
													This action cannot be
													undone.
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
										<button
											type="button"
											className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
											onClick={() => confirmDelete()}
										>
											Delete
										</button>
										<button
											type="button"
											className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
											onClick={() => setOpen(false)}
											ref={cancelButtonRef}
										>
											Cancel
										</button>
									</div>
								</Dialog.Panel>
							</Transition.Child>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		</div>
	);
};
