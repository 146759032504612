/** @format */

import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";
import LanguageSwitcher from "../features/language/language";
import { baseUrl } from "../utils/variables";
import { BookNowButton } from "./BookNowButton";

export const SmallNavbar: React.FC = () => {
	const {t} = useTranslation("");

	const navigation = [
		{name: t("navigation.home"), href: "/"},
		{name: t("navigation.rooms"), href: "/accomodation"},
		{name: t("navigation.restaurant"), href: "/restaurant"},
		{name: t("navigation.products"), href: "/products"},
		{name: t("navigation.explore"), href: "/explore"},
		{name: t("navigation.info"), href: "/info"},
		{name: t("navigation.news"), href: "/news"},
		{name: t("navigation.gallery"), href: "/gallery"},
	];

	return (
		<div className="py-6">
			<ul className="flex flex-col gap-6 lg:flex-row">
				{navigation.map((item) => (
					<li
						key={item.name}
						className="group group-hover:bg-gray-100 transition-all duration-200"
					>
						<a
							href={item.href}
							className="group-hover:text-gray-600 text-gray-900 transition-all duration-200"
						>
							{item.name}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
}

export default function Navbar() {
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const {t} = useTranslation("");

	const navigation = [
		{name: t("navigation.home"), href: "/"},
		{name: t("navigation.rooms"), href: "/accomodation"},
		{name: t("navigation.restaurant"), href: "/restaurant"},
		{name: t("navigation.products"), href: "/products"},
		{name: t("navigation.explore"), href: "/explore"},
		{name: t("navigation.info"), href: "/info"},
		{name: t("navigation.gallery"), href: "/gallery"},
	];

	return (
		<>
			<div className="sticky z-20">
				<nav
					className="flex items-center justify-between gap-2"
					aria-label="Global"
				>
					<div className="lg:flex lg:gap-x-12">
						<Link to="/" className="h-full w-full">
							<img
								className="h-14 md:h-20"
								src={`${baseUrl}/images/Logos/full_margin_transparent_base.png?width=400`}
								alt=""
							/>
						</Link>
					</div>

					<div className="flex lg:hidden items-center gap-8">
						<BookNowButton />
						<button
							type="button"
							className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
							onClick={() => setMobileMenuOpen(true)}
						>
							<span className="sr-only">Open main menu</span>
							<Bars3Icon className="h-6 w-6" aria-hidden="true" />
						</button>
					</div>
					<div className="hidden lg:flex lg:gap-x-6 pl-4">
						{navigation.map((item) => (
							<Link
								key={item.name}
								to={item.href}
								className="text-sm font-semibold leading-6 text-gray-900 hover:text-primary-400 transition-colors"
							>
								{item.name}
							</Link>
						))}
					</div>

					<div className="hidden lg:flex lg:flex-1 lg:justify-end items-center gap-8">
						<BookNowButton />
						<LanguageSwitcher />
					</div>
				</nav>
				<Dialog
					as="div"
					open={mobileMenuOpen}
					onClose={setMobileMenuOpen}
				>
					<Dialog.Panel className="fixed inset-0 z-50 overflow-y-auto bg-secondary-50 px-6 py-6 lg:hidden">
						<div className="flex items-center justify-between">
							<div></div>
							<button
								type="button"
								className="-m-2.5 rounded-md p-2.5 text-gray-700"
								onClick={() => setMobileMenuOpen(false)}
							>
								<span className="sr-only">Close menu</span>
								<XMarkIcon
									className="h-6 w-6"
									aria-hidden="true"
								/>
							</button>
						</div>
						<div className="mt-6 flow-root">
							<div className="-my-6 divide-y divide-gray-500/10">
								<div className="space-y-2 py-6">
									{navigation.map((item) => (
										<Link
											key={item.name}
											to={item.href}
											className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
											onClick={() =>
												setMobileMenuOpen(false)
											}
										>
											{item.name}
										</Link>
									))}
								</div>
								<div className="py-6">
									<LanguageSwitcher />
								</div>
							</div>
						</div>
					</Dialog.Panel>
				</Dialog>
			</div>
		</>
	);
}
