import { createSlice } from "@reduxjs/toolkit";
import { ILanguageState } from "./language.interface";

const initialState: ILanguageState = {
    selectedLocale: "en",
    locales: [{
        id: "en",
        short: "EN",
        name: "English",
        icon: "🇬🇧"
    }, {
        id: "de",
        short: "DE",
        name: "German",
        icon: "🇩🇪"
    },
    {
        id: "nl",
        short: "NL",
        name: "Dutch",
        icon: "🇳🇱"
    },
    {
        id: "fr",
        short: "FR",
        name: "French",
        icon: "🇫🇷"
    },
    {
        id: "it",
        short: "IT",
        name: "Italian",
        icon: "🇮🇹"
    }

    ],
};

export const languageSlice = createSlice({
    name: "language",
    initialState,
    reducers: {
        setLocale: (state, action) => {
            state.selectedLocale = action.payload;
        }
    },
});

export const { setLocale } = languageSlice.actions;
export default languageSlice.reducer;
