/** @format */

import { useTranslation } from "react-i18next";

import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";
import { apiBaseUrl } from "../../utils/variables";

interface ITitleDescription {
	id: string;
	title: string;
	description: string;
	content: string;
}

interface IContent {
	[key: string]: ITitleDescription;
}

interface INewsItem {
	id: string;
	created: string;
	data: IContent;
}

const News: React.FC = () => {
	const {id} = useParams();

	const {i18n} = useTranslation("");
	const [article, setArticle] = useState<INewsItem>({
		id: "",
		created: "",
		data: {},
	});

	useEffect(() => {
		const getNews = async () => {
			const response = await fetch(`${apiBaseUrl}/news/${id}`);
			const article = await response.json();
			setArticle({
				id: article.id,
				created: article.created,
				data: {
					en: {
						id: article.en.id,
						title: article.en.title,
						description: article.en.description,
						content: article.en.content,
					},
					de: {
						id: article.de.id,
						title: article.de.title,
						description: article.de.description,
						content: article.de.content,
					},
					it: {
						id: article.it.id,
						title: article.it.title,
						description: article.it.description,
						content: article.it.content,
					},
					fr: {
						id: article.fr.id,
						title: article.fr.title,
						description: article.fr.description,
						content: article.fr.content,
					},
					nl: {
						id: article.nl.id,
						title: article.nl.title,
						description: article.nl.description,
						content: article.nl.content,
					},
				},
			});
		};

		getNews();
	});

	return (
		<div className="">
			<div className="flex justify-center">
				<div className="flex-grow max-w-6xl px-6 py-6 lg:px-8 lg:py-8 flex flex-col gap-4">
					{article.id !== "" && (
						<div className={"text-2xl font-semibold"}>
							{article.data[i18n.language].title}
						</div>
					)}
					{article.id !== "" && (
						<ReactMarkdown className="prose">
							{article.data[i18n.language].content}
						</ReactMarkdown>
					)}
				</div>
			</div>
		</div>
	);
};

export default News;
