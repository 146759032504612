/** @format */
import React from "react";
import { useTranslation } from "react-i18next";
const Schedule: React.FC = () => {
	const {t} = useTranslation("");
	return (
		<div className="h-full w-full flex flex-col justify-center">
			<ul>
				<li className={""}>
					<b className={"font-bold"}>{t("contact.checkIn")}:</b>&nbsp;{t("info.checkIn")}.
				</li>
				<li className={""}>
					<b className={"font-bold"}>{t("contact.checkOut")}:</b>&nbsp;{t("info.checkOut")}.
				</li>
			</ul>
		</div>
	);
}


export default Schedule;
