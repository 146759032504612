import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import galleryReducer from '../features/gallery/gallery.slice';
import languageReducer from '../features/language/language.slice';
import bookReducer from "./features/Book";

export const store = configureStore({
  reducer: {
    locales: languageReducer,
    gallery: galleryReducer,
    book: bookReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
